import React from 'react';
import { motion } from 'framer-motion'; // Import Framer Motion
import githubIcon from '../assets/github.png'; // Path to your GitHub icon
import linkedinIcon from '../assets/linkedin.png';

const Footer: React.FC = () => {
  return (
    <footer className="bg-primary text-white py-8">
      {/* Resume Link */}
      <div className="flex justify-center mb-4">
        <motion.a
          href="/JamesBrayResume.pdf"
          target="_blank"
          className="text-lg mx-auto text-center font-code font-bold hover:text-primary"
          whileHover={{ scale: 1.1 }} // Add hover animation for growth
          whileTap={{ scale: 0.95 }} // Optional tap animation
        >
          View or Download my Resume
        </motion.a>
      </div>

      <div className="max-w-7xl mx-auto text-center">
        {/* Social Icons */}
        <div className="flex justify-center space-x-6 mb-4">
          {/* GitHub Icon */}
          <motion.a
            href="https://github.com/jameslbray"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }} // Icon grows slightly larger when hovered
            whileTap={{ scale: 0.95 }} // Slightly shrink when clicked
            className="inline-block"
          >
            <motion.img
              src={githubIcon}
              alt="GitHub"
              className="w-8 h-8 hover:opacity-80 transition"
            />
          </motion.a>

          {/* LinkedIn Icon */}
          <motion.a
            href="https://linkedin.com/in/jameslbray"
            target="_blank"
            rel="noopener noreferrer"
            whileHover={{ scale: 1.2 }} // Icon grows slightly larger when hovered
            whileTap={{ scale: 0.95 }} // Slightly shrink when clicked
            className="inline-block"
          >
            <motion.img
              src={linkedinIcon}
              alt="LinkedIn"
              className="w-8 h-8 hover:opacity-80 transition"
            />
          </motion.a>
        </div>

        {/* Copyright Statement */}
        <p className="text-sm font-code">
          © {new Date().getFullYear()} James Bray. All rights reserved.
        </p>
      </div>
    </footer>
  );
};

export default Footer;