import React from 'react';
import { motion } from 'framer-motion';
import profilePic from '../assets/headshot.jpg';


const Hero: React.FC = () => {
  return (
    <section className="min-h-screen flex flex-col justify-center items-center text-center p-6">
      <motion.div
        className="mb-6"
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <img
          src={profilePic}
          alt="Profile"
          className="w-40 h-40 rounded-full shadow-lg border-4 border-gray-300"
        />
      </motion.div>
      <motion.h1
        className="text-4xl sm:text-5xl font-code"
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.3 }}
      >
        hey, i'm James Bray.
      </motion.h1>
      <motion.p
        className="text-lg sm:text-xl text-gray-400 mt-4 max-w-xl font-code"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        I'm a full-stack software engineer with a love for simple design.
      </motion.p>
      {/* Animated Arrow */}
      <motion.div
        className="mt-12"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, repeat: Infinity, repeatType: 'reverse' }}
        whileHover={{ y: 0, scale: 1.2 }} // Stops bouncing and scales slightly on hover
      >
        <a href="#about" className="text-secondary hover:text-accent transition">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="w-10 h-10"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 9l-7 7-7-7"
            />
          </svg>
        </a>
      </motion.div>
    </section>
  );
};

export default Hero;