import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const Contact: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [formStatus, setFormStatus] = useState<'idle' | 'sending' | 'sent' | 'error'>('idle');

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
  
      const formElement = e.currentTarget; // Store reference to the form
  
      setFormStatus('sending');
  
      const formData = {
          user_name: formElement.user_name.value,
          user_email: formElement.user_email.value,
          message: formElement.message.value,
      };
  
      try {
          const response = await fetch('https://emailjs-worker.8w5gcrnfj8.workers.dev', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(formData),
          });
  
          if (response.ok) {
              setFormStatus('sent');
              formElement.reset(); // Use the saved reference
          } else {
              console.error('Non-OK Response:', response.status, await response.text());
              setFormStatus('error');
          }
      } catch (error) {
          console.error('Fetch Error:', error);
          setFormStatus('error');
      }
  };

  return (
    <section id="contact" className="bg-secondary text-white py-16 px-6">
      <motion.div
        className="max-w-5xl mx-auto text-center"
        initial={{ height: 'auto' }}
        animate={{ height: isVisible ? 'auto' : 100 }} // Animate height
        transition={{ duration: 0.5 }}
      >
        {/* Contact Button */}
        <motion.button
    onClick={() => setIsVisible(!isVisible)}
    className="bg-gradient-to-r from-secondary to-accent text-white text-3xl font-code font-bold px-8 py-4 rounded-full shadow-[0_4px_15px_rgba(255,255,255,0.3)] transition-all duration-300 ease-in-out"
    whileHover={{
        scale: 1.1,
        boxShadow: '0px 4px 15px rgba(255, 255, 255, 0.3)',
      }}
    whileTap={{ scale: 0.95 }}
    transition={{ duration: 0.2 }}
    >
    {isVisible ? 'Hide Form' : 'Contact Me'}
    </motion.button>

        {/* Contact Form */}
        <AnimatePresence>
          {isVisible && (
            <motion.div
              className="mt-6"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.25 }}
            >
              <form onSubmit={handleSubmit} className="space-y-4 text-black">
                <input
                    type="text"
                    name="user_name"
                    placeholder="Your Name"
                    className="w-full p-3 rounded-lg text-primary"
                    required
                />
                <input
                    type="email"
                    name="user_email"
                    placeholder="Your Email"
                    className="w-full p-3 rounded-lg text-primary"
                    required
                />
                <textarea
                    name="message"
                    placeholder="Your Message"
                    className="w-full p-3 rounded-lg text-primary h-32"
                    required
                />
                <motion.button
                    type="submit"
                    className={`${
                    formStatus === 'sending' ? 'opacity-50 cursor-not-allowed' : ''
                    } bg-primary text-white text-xl font-code font-bold px-6 py-3 rounded-lg shadow`}
                    whileHover={formStatus === 'sending' ? {} : { scale: 1.1 }}
                    whileTap={formStatus === 'sending' ? {} : { scale: 0.95 }}
                    transition={{ duration: 0.2 }}
                    disabled={formStatus === 'sending'}
                >
                    {formStatus === 'sending'
                    ? 'Sending...'
                    : formStatus === 'sent'
                    ? 'Sent!'
                    : formStatus === 'error'
                    ? 'Error! Try Again'
                        : 'Submit'}
                </motion.button>
                
                </form>
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </section>
  );
      
};

export default Contact;