import React from 'react';
import { motion } from 'framer-motion';

const skills = [
  'JavaScript',
  'TypeScript',
  'React',
  'Node.js',
  'Express',
  'Tailwind CSS',
  'Framer Motion',
    'Git/GitHub',
  'Python'
];

const Skills: React.FC = () => {
  return (
    <section id="skills" className="bg-primary text-white py-16 px-6 font-code">
      <motion.div
        className="max-w-5xl mx-auto text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true, amount: 0.2 }} // Trigger animation when 20% of the section is in view
      >
        {/* Section Heading */}
        <motion.h2
          className="text-3xl font-code font-bold mb-6 "
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
          viewport={{ once: true }}
        >
          Skills
        </motion.h2>

        {/* Skills List */}
        <motion.ul
          className="grid grid-cols-2 sm:grid-cols-3 gap-4 text-lg"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={{
            hidden: { opacity: 0, y: 50 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                staggerChildren: 0.2, // Stagger effect for child items
              },
            },
          }}
        >
          {skills.map((skill) => (
            <motion.li
              key={skill}
              className="bg-secondary text-primary rounded-lg py-3"
              whileHover={{ scale: 1.1 }}
              variants={{
                hidden: { opacity: 0, y: 20 },
                visible: { opacity: 1, y: 0 },
              }}
            >
              {skill}
            </motion.li>
          ))}
        </motion.ul>
      </motion.div>
    </section>
  );
};

export default Skills;