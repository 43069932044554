import React from 'react';
import { motion } from 'framer-motion';

const About: React.FC = () => {
  return (
    <section id="about" className="bg-secondary text-white py-16 px-6 font-code">
      <motion.div
        className="max-w-5xl mx-auto text-center"
        initial={{ opacity: 0, y: 50 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        viewport={{ once: true, amount: 0.2 }} // Animate once when 20% is in view
      >
        <h2 className="text-3xl font-code font-bold mb-6">About Me</h2>
        <p className="text-lg leading-relaxed">
          I'm a passionate web developer with experience in building responsive
          and user-friendly websites. I enjoy solving challenging problems and
          creating clean, efficient code.
        </p>
      </motion.div>
    </section>
  );
};

export default About;