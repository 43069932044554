import React from 'react';
import Footer from './components/Footer';
import About from './sections/About';
import Skills from './sections/Skills';
import Contact from './sections/Contact';
import Hero from './sections/Hero';

const App: React.FC = () => {
  return (
    <>
      <Hero />
      <div id="about">
        <About />
      </div>
      <div id="skills">
        <Skills />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </>
  );
};

export default App;